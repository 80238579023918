.picTextCont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
}
.emojCont {
    display: block;
    position: absolute;
    top: 10px;
    right: 0px;
    background-color: white;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0px 5px;
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}
.emojCont span{
    padding: 5px 10px;
    font-size: 25px;
    cursor: pointer;
}
.picTextCont:hover .emojCont{
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
}