.flip-card-container {
    width: 100%;
    height: 100%;
    perspective: 1000px;
    border-radius: 5px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.5s linear;
    transform-style: preserve-3d;
    box-sizing: border-box;
}

.flip-card-container:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.flip-card-front {
    background-color: #f1f1f1;
}

.flip-card-back {
    background-color: #262626;
    color: white;
    transform: rotateY(180deg);
    display: flex;
    flex-direction: column;
}